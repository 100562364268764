/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useContext } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

// Context
import { ErrorContext } from '../../../context/ErrorContext'

// Services
import api from '../../../services/api'

// Styles
import Container from './styles'

interface ISlidesLayers {
  id: string
  name: string
  file: {
    file_url: string
  }
}

interface ISlides {
  slides: ISlidesLayers[]
}

const Slides: React.FC = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  // Context
  const { ErrorMessage } = useContext(ErrorContext)

  // State
  const [slides, setSlides] = useState<ISlidesLayers[]>([])

  useEffect(() => {
    async function loadSlides() {
      try {
        const response = await api.get<ISlides>('/home/slides')

        setSlides(response.data.slides)
      } catch (err: any) {
        ErrorMessage(err)
      }
    }

    loadSlides()
  }, [ErrorMessage])

  return (
    <Container>
      {/* <div
        draggable="false"
        style={{
          backgroundSize: 'cover',
          backgroundPosition: '50% 0',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(https://uploadyoutwo.s3.amazonaws.com/slide01.png)`,
          width: '100%',
          height: '100%',
        }}
        // onClick={() => console.log('click')}
      /> */}
      {/* <img
        src="https://uploadyoutwo.s3.amazonaws.com/slide01.png"
        alt="YouTwo"
      /> */}
      <Carousel
        responsive={responsive}
        slidesToSlide={1}
        autoPlay={true}
        infinite={true}
        autoPlaySpeed={5000}
        transitionDuration={1000}
        itemAriaLabel="Youtwo"
      >
        {slides
          ? slides.map((i) => (
              <img
                key={i.id}
                src={i.file ? i.file.file_url : ''}
                alt={i.name}
                title={i.name}
                aria-label={i.name}
              />
            ))
          : ''}
      </Carousel>
    </Container>
  )
}

export default Slides
