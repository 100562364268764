/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
`

export const ContactWhatsapp = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
`
