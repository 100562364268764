import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#bfd400',
      light: '#d6e06c',
      dark: '#969512',
      contrastText: '#000000',
    },
    secondary: {
      main: '#000000',
      contrastText: '#3d3d3d',
    },
    common: {
      black: '#000000',
      white: '#ffffff',
    },
    background: {
      default: '#191919',
      paper: '#eeeeee',
    },
    grey: {
      '50': '#fafafa',
      '100': '#f5f5f5',
      '200': '#eeeeee',
      '300': '#e0e0e0',
      '400': '#dbdbdb',
      '500': '#9e9e9e',
      '600': '#757575',
      '700': '#616161',
      '800': '#424242',
      '900': '#212121',
    },
    info: {
      main: '#1976d2',
      light: '#4791db',
      dark: '#115293',
      contrastText: '#ffffff',
    },
    success: {
      main: '#2CAA09',
      light: '#56bb3a',
      dark: '#1e7606',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#d50000',
      light: '#dd3333',
      dark: '#950000',
      contrastText: '#ffffff',
    },
    text: {
      primary: '#3d3d3d',
      secondary: '#757575',
      disabled: '#dddddd',
    },
  },
})

export default theme
