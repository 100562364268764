import React from 'react'

import { Container, Content } from './styles'
import { Paragraph } from '../../../styles/global'

const FooterAllRight: React.FC = () => {
  return (
    <Container>
      <Content>
        <Paragraph color="#fff" colorhover="#fff">
          Copyright © 2024 YouTwo. Todos os direitos reservados.
        </Paragraph>
      </Content>
    </Container>
  )
}

export default FooterAllRight
