import React from 'react'
import { Link } from 'react-router-dom'

import { MdMailOutline, MdPhoneIphone } from 'react-icons/md'
import { FaInstagram } from 'react-icons/fa'

// Styles
import {
  Container,
  Content,
  /* Menu, */ Logo,
  SocialMidia,
  Contact,
} from './styles'
import { TitleH4, Paragraph } from '../../../styles/global'

const Footer: React.FC = () => {
  const logo = 'https://uploadyoutwo.s3.amazonaws.com/logo-preto.png'

  return (
    <Container>
      <Content>
        <ul>
          <li>
            <Logo>
              <Link to="/">
                <img src={logo} alt="YOUTWO" title="YOUTWO" />
              </Link>
            </Logo>

            <Contact>
              <div>
                <MdMailOutline color="#000000" size="24px" />
                <Paragraph
                  color="#000000"
                  colorhover="#000000"
                  fontweight="400"
                >
                  contato@youtwopass.com.br
                </Paragraph>
              </div>
              <div>
                <MdPhoneIphone color="#000000" size="24px" />
                <Paragraph
                  color="#000000"
                  colorhover="#000000"
                  fontweight="400"
                >
                  (51) 98978-1381
                </Paragraph>
              </div>
            </Contact>
          </li>
          <li>
            {/*  <Menu>
              <div>
                <TitleH4 marginbottom="0" color="#000000" colorhover="#000000">
                  MINHA CONTA <hr />
                </TitleH4>
              </div>
              <div>
                <Link to="/">
                  <Paragraph fontweight="400" color="#000000" colorhover="#eee">
                    Meus Dados
                  </Paragraph>
                </Link>
              </div>
              <div>
                <Link to="/">
                  <Paragraph fontweight="400" color="#000000" colorhover="#eee">
                    Login
                  </Paragraph>
                </Link>
              </div>
              <div>
                <Link to="/">
                  <Paragraph fontweight="400" color="#000000" colorhover="#eee">
                    Assine
                  </Paragraph>
                </Link>
              </div>
            </Menu> */}
          </li>
          <li>
            <SocialMidia>
              <TitleH4 color="#000000" colorhover="#000000">
                SIGA-NOS <hr />
              </TitleH4>
              <ul>
                <li>
                  <a
                    href="https://www.instagram.com/youtwopass/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram size="40px" color="#000000" />
                  </a>
                </li>
              </ul>
            </SocialMidia>
          </li>
        </ul>
      </Content>
    </Container>
  )
}

export default Footer
