import React from 'react'
import { ToastContainer } from 'react-toastify'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'

import theme from './theme'

// Routes
import Routes from './routes'

// Context
import { AuthProvider } from './context/AuthContext'
import { ErrorProvider } from './context/ErrorContext'
import { CustomerProvider } from './context/CustomerContext'
import { OrderProvider } from './context/OrderContext'

// Styles
import GlobalStyled from './styles/global'

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ErrorProvider>
        <AuthProvider>
          <CustomerProvider>
            <OrderProvider>
              <ThemeProvider theme={theme}>
                <Routes />
              </ThemeProvider>
            </OrderProvider>
          </CustomerProvider>
        </AuthProvider>
      </ErrorProvider>
      <ToastContainer autoClose={4000} />

      {/* <div
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
        }}
      >
        <img
          style={{ width: '50px' }}
          src="https://uploadyoutwo.s3.us-east-1.amazonaws.com/icon-whatsapp.png"
          alt="Whatsapp"
          title="Whatsapp"
        />
      </div> */}

      <GlobalStyled />
    </BrowserRouter>
  )
}

export default App
