import React from 'react'
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
} from 'react-router-dom'

// Context
import { useAuth } from '../context/AuthContext'

// import AuthLayout from '../pages/_layouts/auth'
import DefaultLayout from '../pages/_layouts/default'

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean
  component: React.ComponentType
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { customer } = useAuth()

  const Layout = customer && isPrivate ? DefaultLayout : DefaultLayout

  return (
    <ReactDOMRoute
      {...rest}
      render={() => {
        return (
          <Layout>
            <Component />
          </Layout>
        )
      }}
    />
  )
}

export default Route
