import styled from 'styled-components'
import { makeStyles } from '@mui/styles'

interface MenuCategoryContentProps {
  visible?: boolean
}

export const Container = styled.div`
  width: 100%;
  height: 70px;
  background: #212121;
  display: flex;
  justify-content: center;
`

export const Content = styled.div`
  width: 100%;
  max-width: 1306px;
  margin: 0px 30px;
  display: flex;
  justify-content: left;
  align-items: center;

  nav {
    display: flex;
    justify-content: left;
    align-items: center;
  }
`
export const Logo = styled.div`
  display: flex;
  align-items: center;

  img {
    height: 100%;
    max-height: 60px;
  }

  @media (max-width: 992px) {
    max-height: 45px;
    min-width: 250px;
  }
`

export const MenuCategory = styled.div`
  height: 50px;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-right: 30px;
`

export const MenuCategoryContent = styled.div<MenuCategoryContentProps>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  //visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  transition: ease-in-out 0.3s;
`

export const useStyles = makeStyles(() => ({
  listItemText: {
    fontSize: '1.6rem',
  },
  list: {
    width: 280,
  },
  drawerHeader: {
    position: 'absolute',
    padding: '0 5px',
    right: 0,
  },
  nested: {
    paddingLeft: '20px',
  },
}))

export const MenuHeaderMobile = styled.div`
  width: 100%;
  display: none;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 768px) {
    display: flex;
  }
`

export const MenuHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 768px) {
    display: none;
  }
`

export const ItensMenu = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 20px;
`

export const ButtonsMenu = styled.div`
  display: block;
`

export const Profile = styled.div`
  width: max-content;
  display: flex;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`

export const ProfileMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    height: 45px;
    border-radius: 50%;
  }
`

export const NavUlLiUlLiUlli = styled.ul`
  padding: 10px;
  width: 200px;
`

export const NavUlLiUlLiUl = styled.ul`
    display: none;
    position: absolute;
    background: #bfd400;
    border-radius: 4px;
  }
`

export const NavUlLiUlLi = styled.ul`
  display: block;
  padding: 10px;
  width: 160px;
  margin-right: 0px;

  &:hover {
    ${NavUlLiUlLiUl} {
      display: block;
      left: 160px;
      margin-top: -30px;
    }
  }
`

export const NavUlLiUl = styled.ul`
  display: none;
  position: absolute;
  background: #bfd400;
  border-radius: 4px;
`

export const NavUlLi = styled.ul`
  display: inline-block;
  margin-right: 25px;

  &:hover {
    ${NavUlLiUl} {
      display: block;
      position: absolute;
    }
  }
`

export const NavUl = styled.ul`
  list-style: none;
  padding: 0;
  z-index: 9;
`
