import styled from 'styled-components'
// import { darken } from 'polished';

export const Container = styled.div`
  width: 100%;
  display: block;
`

export const Content = styled.div`
  width: 100%;
  max-width: 1306px;
  margin: 50px 15px;
`
