import styled from 'styled-components'

const Container = styled.div`
  width: 100%;

  img {
    width: 100%;
  }
`

export default Container
