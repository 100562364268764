import React from 'react'

// Components
// import Categories from '../../../components/home/Categories'
import Slides from '../../../components/home/Slides'
// import ContentHtml from '../../../components/home/ContentHtml'

// Styles
import { Container } from './styles'

const ContentTop: React.FC = () => {
  return (
    <Container>
      <Slides />
      {/* <Categories />
      <ContentHtml /> */}
    </Container>
  )
}

export default ContentTop
