import React, { createContext, useCallback, useState, useContext } from 'react'
import api from '../services/api'

// Context
import { ErrorContext } from './ErrorContext'

interface IOrderLayers {
  id: string
  order_number: number
  transaction_id: string
  transaction_token: string
  transaction_description: string
  tid: string
  authorization_code: string
  subtotal: number
  discount: number
  total: number
  commission: number
  closing_date: Date
  ip: string
  pix_description: string
  pix_qrcode: string
  pix_key: string
  created_at: string
  cardtransactionstatus: {
    id: string
    status: string
    description: string
    authorization: boolean
  }
  passport: {
    id: string
    name: string
    price: number
    validate: number
  }
}

interface IOrder {
  order: IOrderLayers
}

interface OrderContextState {
  order: IOrderLayers
  showOrder(id: string): Promise<void>
}

interface Props {
  children: React.ReactNode
}

const OrderContext = createContext<OrderContextState>({} as OrderContextState)

const OrderProvider: React.FC<Props> = ({ children }) => {
  const { ErrorMessage } = useContext(ErrorContext)

  const [data, setData] = useState<IOrderLayers>({} as IOrderLayers)

  const showOrder = useCallback(
    async (customers_id: string) => {
      try {
        const response = await api.get<IOrder>(
          `/account/orders/activelast/show/${customers_id}`,
        )

        setData(response.data.order)
      } catch (err) {
        ErrorMessage(err)
      }
    },
    [ErrorMessage],
  )

  return (
    <OrderContext.Provider value={{ order: data, showOrder }}>
      {children}
    </OrderContext.Provider>
  )
}

function useOrder(): OrderContextState {
  const context = useContext(OrderContext)

  if (!context) {
    throw new Error('useOrder must be used within an OrderProvider')
  }

  return context
}

export { OrderProvider, useOrder }
