import React, { createContext, useCallback, useState, useContext } from 'react'
import api from '../services/api'

// Context
import { ErrorContext } from './ErrorContext'
import { useAuth } from './AuthContext'

interface ICustomerLayers {
  id: string
  first_name: string
  last_name: string
  document: string
  email: string
  cellphone: string
  password_virtual: string
  newsletter: boolean
  address: string
  number: string
  complement: string
  district: string
  postalcode: string
  city: string
  state: string
  codenf: string
}

interface ICustomer {
  customer: ICustomerLayers
}

interface CustomerContextState {
  customer: ICustomerLayers
  showCustomer(): Promise<void>
  insertCustomerRegister(data: any): Promise<boolean>
  updateCustomer(data: any): Promise<boolean>
}

interface Props {
  children: React.ReactNode
}

const CustomerContext = createContext<CustomerContextState>(
  {} as CustomerContextState,
)

const CustomerProvider: React.FC<Props> = ({ children }) => {
  const { ErrorMessage } = useContext(ErrorContext)
  const { signIn } = useAuth()

  const [data, setData] = useState<ICustomerLayers>({} as ICustomerLayers)

  const showCustomer = useCallback(async () => {
    try {
      const response = await api.get<ICustomer>(`/account/customer/show`)

      console.log(response.data.customer)

      setData(response.data.customer)
    } catch (err) {
      ErrorMessage(err)
    }
  }, [ErrorMessage])

  const insertCustomerRegister = useCallback(
    async ({
      first_name,
      last_name,
      document,
      email,
      cellphone,
      password_virtual,
      newsletter,
      address,
      number,
      complement,
      district,
      postalcode,
      city,
      state,
      codenf,
    }) => {
      try {
        const response = await api.post('/register', {
          files_id: null,
          first_name,
          last_name,
          document: document.replaceAll('.', '').replace('-', ''),
          email,
          cellphone: cellphone
            .replaceAll(/\s/g, '')
            .replace('(', '')
            .replace(')', '')
            .replace('-', ''),
          password: password_virtual,
          newsletter,
          address,
          number,
          complement,
          district,
          postalcode: postalcode.replaceAll(/\s/g, '').replace('-', ''),
          city,
          state,
          codenf,
        })

        setData(response.data.customer)

        await signIn({
          email,
          password: password_virtual,
        })

        return false
      } catch (err) {
        ErrorMessage(err)
        return true
      }
    },
    [ErrorMessage, signIn],
  )

  const updateCustomer = useCallback(
    async ({
      id,
      first_name,
      last_name,
      document,
      cellphone,
      newsletter,
      address,
      number,
      complement,
      district,
      postalcode,
      city,
      state,
      codenf,
    }) => {
      try {
        console.log(id)

        const response = await api.put('/account/customer', {
          customers_id: id,
          first_name,
          last_name,
          document: document.replaceAll('.', '').replace('-', ''),
          cellphone: cellphone
            .replaceAll(/\s/g, '')
            .replace('(', '')
            .replace(')', '')
            .replace('-', ''),
          newsletter,
          address,
          number,
          complement,
          district,
          postalcode,
          city,
          state,
          codenf,
        })

        setData(response.data.customer)
        return false
      } catch (err) {
        ErrorMessage(err)
        return true
      }
    },
    [ErrorMessage],
  )

  return (
    <CustomerContext.Provider
      value={{
        customer: data,
        showCustomer,
        insertCustomerRegister,
        updateCustomer,
      }}
    >
      {children}
    </CustomerContext.Provider>
  )
}

function useCustomer(): CustomerContextState {
  const context = useContext(CustomerContext)

  if (!context) {
    throw new Error('useCustomer must be used within an CustomerProvider')
  }

  return context
}

export { CustomerProvider, useCustomer }
