import React from 'react'

// Styles
import { Container, Content } from './styles'
// import { Paragraph } from '../../../styles/global';

const Dashboard: React.FC = () => {
  return (
    <Container>
      <Content />
    </Container>
  )
}

export default Dashboard
