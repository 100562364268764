import axios from 'axios'

const token = localStorage.getItem('@YoutwoWeb:token')

const api = axios.create({
  baseURL: 'https://youtwo-4b8aca818499.herokuapp.com/web',
  // baseURL: 'http://localhost:3346/web',
  // headers: { Authorization: `Bearer ${token}` },
})

api.defaults.headers.common.Authorization = `Bearer ${token}`

export default api
