import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  background: #bfd400;
  display: flex;
  justify-content: center;
  padding: 50px 0;
  margin-top: 70px;
`

export const Content = styled.div`
  width: 100%;
  max-width: 1306px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ul {
    width: 100%;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;

    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  ul li {
    display: flex;
    flex-direction: column;
  }
`

export const Logo = styled.div`
  display: flex;
  justify-content: left;

  img {
    width: 100%;
    max-width: 300px;
    max-height: 90px;
  }
`

export const Contact = styled.div`
  width: 100%;

  div {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    svg {
      margin-right: 10px;
    }
  }
`

export const Menu = styled.div`
  width: 100%;

  div {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    svg {
      margin-right: 10px;
    }
  }
`

export const SocialMidia = styled.div`
  width: 100%;

  ul {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;

    @media (max-width: 320px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  ul li {
    display: flex;
    flex-direction: column;

    svg {
      color: #fff;

      &:hover {
        color: #eee;
        transition: color 0.3s;
      }
    }
  }
`
