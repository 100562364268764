import React from 'react'
import { Switch } from 'react-router-dom'

import Route from './Route'
// import RoutePOS from './RoutePOS'

import Home from '../pages/common/Home'

/* import Quemsomos from '../pages/Quemsomos'

import AccountAccess from '../pages/account/Access'
import CustomersServices from '../pages/account/CustomersServices'

import ProfessionalsServices from '../pages/account_professional/ProfessionalsServices'

import Checkout from '../pages/Checkout'
import Sub1Categories from '../pages/categories/Sub1Categories'
import Professionals from '../pages/categories/Professionals'
import Professional from '../pages/Professional' */

/* import SingIn from '../pages/SingIn' */

// import Dashboard from '../pages/Dashboard'

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Home} />

    {/*  <Route path="/quemsomos" exact component={Quemsomos} />

    <Route path="/account" exact component={AccountAccess} />
    <Route
      path="/account/customerservice"
      exact
      component={CustomersServices}
    />

    <Route
      path="/accountprofessional/professionalservice/:id"
      exact
      component={ProfessionalsServices}
    />

    <Route path="/categorias/:id" exact component={Sub1Categories} />
    <Route path="/checkout" exact component={Checkout} />
    <Route path="/professional/:id" exact component={Professional} />
    <Route path="/professionals" exact component={Professionals} /> */}

    {/* <Route path="/" component={Home} /> */}
  </Switch>
)

export default Routes
