import React /*  useCallback, */ /* useState,
  useEffect */ /* Fragment, */ /* useCallback,  ,
  /* Fragment, */ from 'react'
import { Link /* , useHistory */ } from 'react-router-dom'
/* import {
  Drawer,
  Collapse,
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  Divider,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
} from '@mui/material' */

// import { FaBars, FaAngleDown, FaAngleUp } from 'react-icons/fa'
/* import { IoGridOutline } from 'react-icons/io5' */
// import { MdLogout, MdOutlineSettingsAccessibility } from 'react-icons/md'

// import { useAuth } from '../../../context/AuthContext'

// Services
// import api from '../../../services/api'

// Components

// Styles
import {
  Container,
  Content,
  Logo,
  /*   MenuHeader,
  Profile,
  ButtonsMenu,
  NavUl,
  NavUlLi,
  NavUlLiUl,
  NavUlLiUlLi,
  NavUlLiUlLiUl,
  NavUlLiUlLiUlli,
  MenuHeaderMobile, */
  /*   MenuCategory,
  MenuCategoryContent, */
  /* useStyles, */
} from './styles'
// import { Paragraph, Buttons, Button } from '../../../styles/global'

/* interface IContents {
  id: number;
  title: [
    {
      language: string;
      title_description: string;
    },
  ];
  categories: string[];
} */

/* interface IModules {
  id: string
  position: string
  route: string
  status: boolean
  code: string
  setting: string
} */

/* interface ISub1categoriesLayers {
  id: string
  name: string
  status: boolean
  file: {
    file_url: string
  }
}

interface ISubcategoriesLayers {
  id: string
  name: string
  status: boolean
  sub1category: ISub1categoriesLayers[]
}

interface ICategoriesLayers {
  id: string
  name: string
  status: boolean
  subcategory: ISubcategoriesLayers[]
} */

/* interface ICategories {
  categories: ICategoriesLayers[]
}

interface IListC {
  id: string
  open_list: boolean
}

interface IListSub {
  id: string
  submenu: {
    id: string
    open_list: boolean
  }[]
} */

const Header: React.FC = () => {
  // const classes = useStyles();
  // const history = useHistory()

  // const { customer, signOut } = useAuth()

  // State
  /* const [categories, setCategories] = useState<ICategoriesLayers[]>([])
  const [openDrawer, setOpenDrawer] = useState(false)
  const [openC, setOpenC] = useState<IListC[]>([])
  const [openSub, setOpenSub] = useState<IListSub[]>([])

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const [anchorElMobile, setAnchorElMobile] = useState<null | HTMLElement>(null)
  const openMobile = Boolean(anchorElMobile) */

  const logo = 'https://uploadyoutwo.s3.amazonaws.com/logo-branco.png'

  /* useEffect(() => {
    async function loadCategories() {
      try {
        const response = await api.get<ICategories>('/home/categories')

        setCategories(response.data.categories)

        const data = response.data.categories.map((c) => ({
          id: c.id,
          open_list: false,
        }))

        setOpenC(data)

        const dataSub = response.data.categories.map((c) => ({
          id: c.id,
          submenu: c.subcategory.map((s) => ({
            id: s.id,
            open_list: false,
          })),
        }))

        setOpenSub(dataSub)
      } catch (err) {
        // ErrorMessage(err)
      }
    }

    loadCategories()
  }, []) */

  /*  const handleToggleDrawer = useCallback((newOpen: boolean) => {
    setOpenDrawer(newOpen)
  }, [])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickMobile = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMobile(event.currentTarget)
  }
  const handleCloseMobile = () => {
    setAnchorElMobile(null)
  }

  const handleClickList = (id: string) => {
    const data = openC.map((ol) => ({
      id: ol.id,
      open_list: ol.id === id && !ol.open_list,
    }))

    setOpenC(data)
  }

  const handleClickListSub = (id: string) => {
    const data = openSub.map((ol) => ({
      ...ol,
      submenu: ol.submenu.map((sm) => ({
        id: sm.id,
        open_list: sm.id === id && !sm.open_list,
      })),
    }))

    setOpenSub(data)
  }

  const handleSignIn = useCallback(() => {
    history.push('/account')
  }, [history])

  const handleSignOut = useCallback(() => {
    signOut()
    setOpenDrawer(false)
  }, [signOut]) */

  /*   const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation">
      <List>
        {customer ? (
          <>
            <List>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Tooltip title="Minha Conta">
                  <IconButton
                    onClick={handleClickMobile}
                    size="small"
                    aria-controls={
                      openMobile ? 'account-menu-mobile' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openMobile ? 'true' : undefined}
                  >
                    <img
                      src={
                        'https://uploadyoutwo.s3.us-east-1.amazonaws.com/avatar.png'
                      }
                      alt={customer.first_name}
                      style={{ width: '48px', height: '48px' }}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
              <ListItemButton
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                onClick={() => handleToggleDrawer(false)}
              >
                <Profile>
                  <Link to="/dashboard">
                    <Paragraph
                      color="#bfd400"
                      colorhover="#fff"
                      align="right"
                      marginright="10"
                      fontweight="600"
                    >
                      {`Olá, ${customer.first_name}.`}
                    </Paragraph>
                  </Link>
                </Profile>
              </ListItemButton>

              <Menu
                anchorEl={anchorElMobile}
                id="account-menu-mobile"
                open={openMobile}
                onClose={handleCloseMobile}
                onClick={handleCloseMobile}
                slotProps={{
                  paper: {
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem>
                  <img
                    src={
                      'https://uploadyoutwo.s3.us-east-1.amazonaws.com/avatar.png'
                    }
                    alt={customer.first_name}
                    style={{
                      width: '25px',
                      height: '25px',
                      marginRight: '15px',
                    }}
                  />

                  <Paragraph
                    color="#3d3d3d"
                    colorhover="#3d3d3d"
                    marginright="0"
                    fontweight="400"
                  >
                    Minha Conta
                  </Paragraph>
                </MenuItem>
                <Divider />
                <MenuItem>
                  <Link
                    style={{ display: 'flex' }}
                    to="/account/customerservice"
                  >
                    <ListItemIcon>
                      <MdOutlineSettingsAccessibility size={20} />
                    </ListItemIcon>
                    <Paragraph
                      color="#3d3d3d"
                      colorhover="#3d3d3d"
                      marginright="0"
                      fontweight="400"
                    >
                      Meus Serviços
                    </Paragraph>
                  </Link>
                </MenuItem>
              </Menu>
            </List>
          </>
        ) : (
          <List>
            <ListItemButton onClick={handleSignIn}>
              <ButtonsMenu>
                <Buttons width="max-content" align="flex-end">
                  <Button width="max-content" background="#bfd400" color="#fff">
                    Entrar
                  </Button>
                </Buttons>
              </ButtonsMenu>
            </ListItemButton>
          </List>
        )}
      </List>
      <Divider />
      <List>
        {categories
          .filter((fc) => fc.status === true)
          .map((c) => (
            <Fragment key={c.id}>
              {c.subcategory ? (
                <>
                  <ListItemButton onClick={() => handleClickList(c.id)}>
                    <Paragraph
                      style={{ cursor: 'pointer' }}
                      fontweight="600"
                      marginright="0"
                      color="#191919"
                      colorhover="#bfd400"
                    >
                      {c.name}
                    </Paragraph>
                    {openC.find(
                      (ol) => ol.id === c.id && ol.open_list === true,
                    ) ? (
                      <FaAngleUp />
                    ) : (
                      <FaAngleDown />
                    )}
                  </ListItemButton>
                  <Collapse
                    in={
                      !!openC.find(
                        (ol) => ol.id === c.id && ol.open_list === true,
                      )
                    }
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {c.subcategory
                        .filter((fs) => fs.status === true)
                        .map((s) => (
                          <Fragment key={s.id}>
                            {s.sub1category ? (
                              <>
                                <ListItemButton
                                  sx={{ pl: 4 }}
                                  onClick={() => handleClickListSub(s.id)}
                                >
                                  <Paragraph
                                    style={{ cursor: 'pointer' }}
                                    fontweight="600"
                                    marginright="0"
                                    color="#191919"
                                    colorhover="#bfd400"
                                  >
                                    {s.name}
                                  </Paragraph>
                                  {openSub.find((ol) =>
                                    ol.submenu.find(
                                      (ols) =>
                                        ols.id === s.id &&
                                        ols.open_list === true,
                                    ),
                                  ) ? (
                                    <FaAngleUp />
                                  ) : (
                                    <FaAngleDown />
                                  )}
                                </ListItemButton>
                                <Collapse
                                  in={
                                    !!openSub.find((ol) =>
                                      ol.submenu.find(
                                        (ols) =>
                                          ols.id === s.id &&
                                          ols.open_list === true,
                                      ),
                                    )
                                  }
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <List component="div" disablePadding>
                                    {s.sub1category
                                      .filter((fs1) => fs1.status === true)
                                      .map((s1) => (
                                        <ListItemButton
                                          key={s1.id}
                                          sx={{ pl: 6 }}
                                          onClick={() =>
                                            handleToggleDrawer(false)
                                          }
                                        >
                                          <Link to={`/categorias/${s1.id}`}>
                                            <Paragraph
                                              style={{ cursor: 'pointer' }}
                                              fontweight="600"
                                              marginright="0"
                                              color="#191919"
                                              colorhover="#bfd400"
                                            >
                                              {s1.name}
                                            </Paragraph>
                                          </Link>
                                        </ListItemButton>
                                      ))}
                                  </List>
                                </Collapse>
                              </>
                            ) : null}
                          </Fragment>
                        ))}
                    </List>
                  </Collapse>
                </>
              ) : (
                <ListItemButton onClick={() => handleToggleDrawer(false)}>
                  <Paragraph
                    style={{ cursor: 'pointer' }}
                    fontweight="600"
                    marginright="0"
                    color="#191919"
                    colorhover="#bfd400"
                  >
                    {c.name}
                  </Paragraph>
                </ListItemButton>
              )}
            </Fragment>
          ))}
        <ListItemButton onClick={() => handleToggleDrawer(false)}>
          <Link to="/professionals">
            <Paragraph
              style={{ cursor: 'pointer' }}
              fontweight="600"
              marginright="0"
              color="#191919"
              colorhover="#bfd400"
            >
              Profissionais
            </Paragraph>
          </Link>
        </ListItemButton>
      </List>
      <Divider />
      <List>
        <ListItemButton onClick={handleSignOut}>
          <ListItemIcon>
            <MdLogout size={20} />
          </ListItemIcon>
          <Paragraph
            style={{ cursor: 'pointer' }}
            fontweight="600"
            marginright="0"
            color="#191919"
            colorhover="#bfd400"
          >
            Sair
          </Paragraph>
        </ListItemButton>
      </List>
    </Box>
  ) */

  return (
    <Container>
      <Content>
        <Link to={`/`} style={{ fontSize: '0' }}>
          <Logo>
            <img src={logo} alt="Youtwo" title="Youtwo" />
          </Logo>
        </Link>

        {/*         <MenuHeaderMobile>
          <nav>
            <FaBars
              size="30"
              color="#fff"
              onClick={() => handleToggleDrawer(!openDrawer)}
            />
          </nav>
        </MenuHeaderMobile>

        <MenuHeader>
          <NavUl>
            {categories
              .filter((fc) => fc.status === true)
              .map((c) => (
                <NavUlLi key={c.id}>
                  <Link to={`/#`}>
                    <Paragraph
                      style={{ cursor: 'pointer' }}
                      fontweight="600"
                      marginright="0"
                      color="#fff"
                      colorhover="#bfd400"
                    >
                      {c.name}
                    </Paragraph>
                  </Link>
                  <NavUlLiUl>
                    {c.subcategory
                      .filter((fs) => fs.status === true)
                      .map((s) => (
                        <NavUlLiUlLi key={s.id}>
                          <Link to={`/#`}>
                            <Paragraph
                              style={{ cursor: 'pointer' }}
                              fontweight="600"
                              marginright="0"
                              color="#fff"
                              colorhover="#191919"
                            >
                              {s.name}
                            </Paragraph>
                          </Link>
                          <NavUlLiUlLiUl>
                            {s.sub1category
                              .filter((fs1) => fs1.status === true)
                              .map((s1) => (
                                <NavUlLiUlLiUlli key={s1.id}>
                                  <Link to={`/categorias/${s1.id}`}>
                                    <Paragraph
                                      style={{ cursor: 'pointer' }}
                                      fontweight="600"
                                      marginright="0"
                                      color="#fff"
                                      colorhover="#191919"
                                    >
                                      {s1.name}
                                    </Paragraph>
                                  </Link>
                                </NavUlLiUlLiUlli>
                              ))}
                          </NavUlLiUlLiUl>
                        </NavUlLiUlLi>
                      ))}
                  </NavUlLiUl>
                </NavUlLi>
              ))}
            <NavUlLi>
              <Link to="/professionals">
                <Paragraph
                  style={{ cursor: 'pointer' }}
                  fontweight="600"
                  marginright="30"
                  color="#fff"
                  colorhover="#bfd400"
                >
                  Profissionais
                </Paragraph>
              </Link>
            </NavUlLi>
            <NavUlLi>
              <Link to="/quemsomos">
                <Paragraph
                  style={{ cursor: 'pointer' }}
                  fontweight="600"
                  marginright="30"
                  color="#fff"
                  colorhover="#bfd400"
                >
                  Quem somos
                </Paragraph>
              </Link>
            </NavUlLi>
          </NavUl>

          {customer ? (
            <>
              <Profile>
                <Link to="/dashboard">
                  <Paragraph
                    color="#bfd400"
                    colorhover="#fff"
                    align="right"
                    marginright="10"
                    fontweight="600"
                  >
                    {`Olá, ${customer.first_name}.`}
                  </Paragraph>
                </Link>
              </Profile>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <Tooltip title="Minha Conta">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <img
                      src={
                        'https://uploadyoutwo.s3.us-east-1.amazonaws.com/avatar.png'
                      }
                      alt={customer.first_name}
                      style={{ width: '48px', height: '48px' }}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={{
                  paper: {
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem>
                  <img
                    src={
                      'https://uploadyoutwo.s3.us-east-1.amazonaws.com/avatar.png'
                    }
                    alt={customer.first_name}
                    style={{
                      width: '25px',
                      height: '25px',
                      marginRight: '15px',
                    }}
                  />

                  <Paragraph
                    color="#3d3d3d"
                    colorhover="#3d3d3d"
                    marginright="0"
                    fontweight="400"
                  >
                    Minha Conta
                  </Paragraph>
                </MenuItem>
                <Divider />
                <MenuItem>
                  <Link
                    style={{ display: 'flex' }}
                    to="/account/customerservice"
                  >
                    <ListItemIcon>
                      <MdOutlineSettingsAccessibility size={20} />
                    </ListItemIcon>
                    <Paragraph
                      color="#3d3d3d"
                      colorhover="#3d3d3d"
                      marginright="0"
                      fontweight="400"
                    >
                      Meus Serviços
                    </Paragraph>
                  </Link>
                </MenuItem>
                <Divider />
                <MenuItem>
                  <ListItemIcon>
                    <MdLogout size={20} />
                  </ListItemIcon>
                  <Paragraph
                    color="#3d3d3d"
                    colorhover="#3d3d3d"
                    marginright="0"
                    fontweight="400"
                    onClick={handleSignOut}
                  >
                    Sair
                  </Paragraph>
                </MenuItem>
              </Menu>
            </>
          ) : (
            <ButtonsMenu>
              <Buttons width="max-content" align="flex-end">
                <Button
                  width="max-content"
                  background="#bfd400"
                  color="#fff"
                  onClick={handleSignIn}
                >
                  Entrar
                </Button>
              </Buttons>
            </ButtonsMenu>
          )}
        </MenuHeader> */}
      </Content>
      {/*       <Drawer variant="persistent" anchor="left" open={openDrawer}>
        {DrawerList}
      </Drawer> */}
    </Container>
  )
}

export default Header
