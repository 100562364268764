import React, { createContext, useCallback, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import api from '../services/api'

// Context
import { ErrorContext } from './ErrorContext'

interface Customer {
  id: string
  first_name: string
  last_name: string
  file_url: string
}

interface AuthState {
  token: string
  customer: Customer // Idendifica que é um objeto
}

interface SignInCredencials {
  email: string
  password: string
}

interface AuthContextState {
  token: string
  customer: Customer
  signIn(credencials: SignInCredencials): Promise<boolean>
  signOut(): void
}

interface Props {
  children: React.ReactNode
}

const AuthContext = createContext<AuthContextState>({} as AuthContextState)

const AuthProvider: React.FC<Props> = ({ children }) => {
  const { ErrorMessage } = useContext(ErrorContext)
  const history = useHistory()
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@YoutwoWeb:token')
    const customer = localStorage.getItem('@YoutwoWeb:customer')

    if (token && customer) {
      return { token, customer: JSON.parse(customer) }
    }

    return {} as AuthState
  })

  const signIn = useCallback(
    async ({ email, password }) => {
      try {
        const response = await api.post('/account/sessions', {
          email,
          password,
        })

        const { token, customer } = response.data

        localStorage.setItem('@YoutwoWeb:token', token)
        localStorage.setItem('@YoutwoWeb:customer', JSON.stringify(customer))

        api.defaults.headers.common.Authorization = `Bearer ${token}`

        setData({ token, customer })

        return false
      } catch (err) {
        ErrorMessage(err)
        return true
      }
    },
    [ErrorMessage],
  )

  const signOut = useCallback(() => {
    localStorage.removeItem('@YoutwoWeb:token')
    localStorage.removeItem('@YoutwoWeb:customer')
    localStorage.removeItem('@YoutwoWeb:order')

    setData({} as AuthState)

    history.push('/')
  }, [history])

  return (
    <AuthContext.Provider
      value={{
        token: data.token,
        customer: data.customer,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function useAuth(): AuthContextState {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}

export { AuthProvider, useAuth }
