import React from 'react'

import Header from '../../../components/common/Header'
import Footer from '../../../components/common/Footer'
import FooterAllRights from '../../../components/common/FooterAllRights'

import { Wrapper, Container } from './styles'

interface Props {
  children: React.ReactNode
}

const DefaultLayout: React.FC<Props> = ({ children }) => {
  return (
    <Wrapper>
      <Header />
      <Container>{children}</Container>
      <Footer />
      <FooterAllRights />
    </Wrapper>
  )
}

export default DefaultLayout
