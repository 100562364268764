import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  background: #201e1e;
  display: flex;
  justify-content: center;
`

export const Content = styled.div`
  width: 100%;
  max-width: 1306px;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
